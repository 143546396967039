/*@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');*/
@font-face {
  font-family: "Playfair";
  src: url("../fonts/PlayfairDisplay-VariableFont_wght.ttf");
  font-display: swap;
}

body {
  padding: 0;
  color: white;
  font-family: 'Playfair', serif;
  background-color: #ffffff;
  position: relative;
}

a {
  text-decoration: none;
  color: white;
}

.chevron {
  position: fixed;
  margin: 0;
  bottom: 30px;
  right: 30px;
  height: 40px;
  width: 40px;
  font-size: 50px;
  background-color: #1d355ee5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/**
*HEADER ********************
*/

.header {
  width: 100%;
  height: 15vh;
  margin: 0 0 5vh 0;
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: 0px;
  color: #1d355e;
  background-color: #ebebeb;
  -webkit-box-shadow: 0px 14px 20px 0px rgba(29,53,94,0.6);
  -moz-box-shadow: 0px 14px 20px 0px rgba(29,53,94,0.6);
  box-shadow: 0px 14px 20px 0px rgba(29,53,94,0.6);
}

.header-logo {
  height: 11vh;
  position: -webkit-sticky;
  position: sticky;
  top: 2vh;
  left: 3vh;
}

.contact-container {
  height: 15vh;
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.contact-container a {
  color: #1d355e;
}

.contact-container a:hover {
  color: white;
}

.contact {
  height: 15vh;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  cursor: pointer;
  width: 120px;
  transition: 1.2s;
  background-color: #ebebeb;
}

.contact:hover {
  width: 380px;
  background-color: #1d355ee5;
  white-space: nowrap;
}

/**
* MOBILE MENU*******************
*/

.mobile-menu-toggle {
  display: none;
  cursor: pointer;
}

.mobile-contact-container {
  display: none;
}

/**
*OVERALL VIEW PORTS
*/

#lazy-load-poster {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-image: url('../2d-images/Poster.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}

#card {
  margin: 3em auto;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  border-radius: 6px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

model-viewer {
  width: 60%;
  height: 74vh;
  margin: 0 2vh 0 0;
  border: 4px solid #248ec2;
  background-color: #ebebeb;
  -webkit-box-shadow: 10px 10px 20px 0px rgba(29,53,94,1);
  -moz-box-shadow: 10px 10px 20px 0px rgba(29,53,94,1);
  box-shadow: 10px 10px 20px 0px rgba(29,53,94,1);
}

.attribution {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em;
}

.attribution h1 {
  margin: 0 0 0.25em;
}

.attribution img {
  opacity: 0.5;
  height: 2em;
}

.attribution .cc {
  flex-shrink: 0;
  text-decoration: none;
}

/**
*CONDITIONS***************************
*/

.conditions {
  width: 35%;
  min-width: 350px;
  height: 74vh;
  min-height: 74vh;
  overflow: scroll;
  border: 4px solid #248ec2;
  float: left;
  background-color: #ebebeb;
  color: #1d355e;
  -webkit-box-shadow: 10px 10px 20px 0px rgba(29,53,94,0.9);
  -moz-box-shadow: 10px 10px 20px 0px rgba(29,53,94,0.9);
  box-shadow: 10px 10px 20px 0px rgba(29,53,94,0.9);
}

.conditions-header {
  height: 20%;
  padding: 20px 0 10px 0;
  background-color: #248ec2;
}

.conditions-header h1 {
  margin: 0;
  color: #1d355e;
  text-align: center;
  font-size: 3vh;
}

.conditions-header h2 {
  text-align: center;
  color: #1d355e;
  font-size: 2.4vh;
}

.condition-container {
  width: 100%;
  height: 78%;
  padding: 0 15px 0 15px;
  overflow: scroll;
}

.t-image-border {
  text-align: center;
}

.title-image {
  margin: auto;
  border: 2px solid #248ec2;
  width: 80%;
  min-width: 280px;
  -webkit-box-shadow: 10px 10px 46px -17px rgba(29,53,94,0.7);
  -moz-box-shadow: 10px 10px 46px -17px rgba(29,53,94,0.7);
  box-shadow: 10px 10px 46px -17px rgba(29,53,94,0.7);
}

/**
*BUTTONS!!!!!
*/

button{
  display: block;
  border: none;
  outline: none;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
}

button[slot="hotspot-ankle"]{
  --min-hotspot-opacity: 0;
  background-image: radial-gradient(rgba(255, 0, 0, 0.267) 0.1%, rgba(255, 255, 255, 0)99%);
  width: 30px;
  height: 50px;
  border-radius: 100%;
}

button[slot="hotspot-hallux"]{
  --min-hotspot-opacity: 0;
  background-image: radial-gradient(rgba(255, 0, 0, 0.267) 0.1%, rgba(255, 255, 255, 0)99%);
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

button[slot="hotspot-toes"]{
  --min-hotspot-opacity: 0;
  background-image: radial-gradient(rgba(255, 0, 0, 0.267) 0.1%, rgba(255, 255, 255, 0)99%);
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

button[slot="hotspot-heel"]{
  --min-hotspot-opacity: 0;
  background-image: radial-gradient(rgba(255, 0, 0, 0.267) 0.1%, rgba(255, 255, 255, 0)99%);
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

button[slot="hotspot-achilles"]{
  --min-hotspot-opacity: 0;
  background-image: radial-gradient(rgba(255, 0, 0, 0.267) 0.1%, rgba(255, 255, 255, 0)99%);
  width: 30px;
  height: 80px;
  border-radius: 100%;
}

button[slot="hotspot-sole"]{
  --min-hotspot-opacity: 0;
  background-image: radial-gradient(rgba(255, 0, 0, 0.267) 0.1%, rgba(255, 255, 255, 0)99%);
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

#annotation{
  background-color: #1d355e;
  color: white;
  position: absolute;
  transform: translate(10px, 10px);
  border-radius: 10px;
  padding: 10px;
  width: 200px;
  text-align: left;
}

#annotation a {
  color: white;
}
/* This keeps child nodes hidden while the element loads */
:not(:defined) > * {
  display: none;
}


/**
*ANIMATIONS**********************
*/

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}

@keyframes fadeInOpacity {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}