@media only screen and (max-width: 1025px) {
    .header {
        margin: 0 0 3vh 0;
    }

    model-viewer {
        width: 90%;
        height: 400px;
        margin: 0 0 3vh 0;
    }

    .conditions {
        width: 90%;
        height: auto;
    }

    .conditions-header {
        height: auto;
    }

    .conditions h3 {
        text-align: center;
        font-size: 30px;
    }

    .condition-container {
        width: 100%;
        height: auto;
        margin: auto;
    }

    .condition-paragraphs {
        width: 70%;
        margin: auto;
    }

    .contact-container {
        display: none;
    }

    /**
    *MOBILE MENU********************
    */

    .mobile-menu-toggle {
        position: fixed;
        top: 0;
        right: 0;
        margin: 0 3vh 0 0;
        display: flex;
        height: 15vh;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
    }

    .mobile-contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 15vh;
        right: 0;
        background-color: #1d355ef1;
        width: 100%;
        height: 100vh;
    } 

    .header-links {
        margin: 5vh 0 5vh 0;
    }

    .contact {
        background-color: #1d355e00;
        width: 380px;
        height: 3vh;
        justify-content: center;
        transition: 1s;
    }

    .contact:hover {
        width: 380px;
        background-color: #248ec2;
        white-space: nowrap;
      }

  }

  @media only screen and (max-width: 500px) {
    model-viewer {
        width: 90%;
        height: 40vh;
        margin: 0 0 3vh 0;
        cursor: pointer;
    }

    .conditions {
        margin: 0;
        padding: 0;
        width: 90%;
        min-width: 200px;
        height: auto;
    }

    .conditions h1 {
        font-size: 18px;
    }

    .conditions h2 {
        font-size: 14px;
    }

    .title-image {
        width: 98%;
        min-width: 200px;
    }

    .conditions h3 {
        text-align: center;
        font-size: 30px;
    }

    .condition-container {
        width: 100%;
        margin: auto;
        padding: 15px 0;
    }

    .condition-paragraphs {
        width: 70%;
        margin: auto;
    }

    .conditions h3 {
        font-size: 16px;
    }

    .conditions p {
        font-size: 12px;
    }

    .conditions ul {
        font-size: 12px;
    }

    #annotation{
        font-size: 10px;
        width: 80px;
    }
  }